<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Ziffernrechnen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller">Was erwartet Sie im Inhaltsbereich Ziffernrechnen?</v-card-title>
      <v-card-text>
        Beim Ziffernrechnen, auch schriftliches Rechnen genannt, sind drei
        grundlegende Aspekte von Bedeutung, die im Hintergrundwissen genauer
        erläutert werden:
        <ol>
          <li>Verfahrensweisen</li>
          <li>Verstehensorientierte Erarbeitung</li>
          <li>Flexibles Rechnen</li>
        </ol>
        Im Anschluss werden anhand von Kinderbeispielen exemplarisch Diagnose-
        und Förderaufgaben gestellt, sodass Sie die Facetten des
        Hintergrundwissens anwenden können.
        <br />
        Den Abschluss bildet eine Kompetenzübersicht, sodass noch einmal
        transparent wird, was im Inhaltsbereich des Ziffernrechnens als zentral
        gelten kann.
      </v-card-text>
    </v-card>

    <br />

    <br />

    <v-card outlined>
      <v-card-title>Weitere Ressourcen
      </v-card-title>
      <v-card-text>
        Wenn Sie sich weiterführend mit dem Thema Ziffernrechnen auseinandersetzen möchten, können Sie auch folgende Angebote der Plattform KIRA nutzen.
        <br />
        <ul style="list-style-type: none; padding-left: 10px">
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/619" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Schriftliches Rechnen</a></li>
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/803" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Weiterführung schriftliches Rechnen</a></li>
        </ul>
      </v-card-text>
    </v-card>

    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>
    <AppBottomNavZR next="/ziffernrechnen/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
export default {
  components: {
    AppBottomNavZR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}

#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
